import { myRentalsShallowMount } from "@/tests/unit/setup";
import PropertyActiveProcessCard from "./PropertyActiveProcessCard.vue";
import { PropertyActiveProcessCardType } from "@/enums/PropertyCardType";
import { RentalLeasingStatus } from "@/enums/RentalLeasing";
import { IRentalLeasings, IRentalLeasing } from "@/models/RentalLeasing";

const createMockLeasing = (
  status: RentalLeasingStatus,
  tenantNames: string[]
): IRentalLeasing => ({
  leasingId: 1,
  lastStatusChangedAt: "2024-01-01",
  status,
  tenantNames,
  monthlyPrice: 150000,
  leasingContractValidityDate: "2024-01-01",
  leasingTerminationDate: null,
});

const mockLeasingsWithTenants: IRentalLeasings = {
  items: [
    createMockLeasing(RentalLeasingStatus.ONGOING, ["John Doe", "Jane Smith"]),
  ],
};

const mockLeasingsWithoutTenants: IRentalLeasings = {
  items: [createMockLeasing(RentalLeasingStatus.ONGOING, [])],
};

const component = (leasings: IRentalLeasings) => {
  return myRentalsShallowMount(PropertyActiveProcessCard, {
    props: {
      cardType: PropertyActiveProcessCardType.IN_MANAGEMENT,
      info: {},
      leasings,
      dataQa: "test-card",
    },
  });
};

describe("PropertyActiveProcessCard", () => {
  describe("Computed", () => {
    describe("infoTexts", () => {
      it("should display tenant names correctly when there are tenants", () => {
        // given
        const wrapper = component(mockLeasingsWithTenants);

        // when
        const tenantNamesText = wrapper.vm.infoTexts?.tenantNames?.value;

        // then
        expect(tenantNamesText).toBe("John Doe, Jane Smith");
      });

      it("should handle empty tenant names array", () => {
        // given
        const wrapper = component(mockLeasingsWithoutTenants);

        // when
        const tenantNamesText = wrapper.vm.infoTexts?.tenantNames?.value;

        // then
        expect(tenantNamesText).toBe("");
      });
    });
  });
});
